import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HelpIcon from '@mui/icons-material/Help';
import MoreVertIcon from '@mui/icons-material/MoreVertOutlined';
import React, { useState } from 'react';

import {
  Box,
  Button,
  Drawer,
  Grid,
  RteContent,
  Tooltip,
  Typography,
  useCoreConfig,
  useGetHeaderContent,
  useIsMediumScreen,
} from '@sigfig/digital-wealth-core';

import { LogoutButton } from '../../auth';
import { PartnerConfig } from '../../config';

interface Props {
  dataQa?: string;
  onBack?: () => void;
  showBackToAccountDetails?: boolean;
  showBackToAccountSummary?: boolean;
  showLogout?: boolean;
  showPerformanceTitle?: boolean;
}

export const Header: React.FC<Props> = ({
  dataQa = 'header',
  showBackToAccountSummary = false,
  showBackToAccountDetails = false,
  showLogout = true,
  showPerformanceTitle = false,
  onBack,
}): any => {
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const isMobile = useIsMediumScreen();
  const [open, setOpen] = useState(false);
  const { data: headerComponentData } = useGetHeaderContent({
    variables: contentOptions,
  });
  const content = headerComponentData?.all_header?.items?.[0];

  const getMenuActions = () => (
    <Grid
      alignItems={isMobile ? 'left' : 'center'}
      container
      flexDirection={isMobile ? 'column' : 'row'}
      height="100%"
      justifyContent="right"
    >
      {content?.help_tooltip_content && (
        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tooltip
            tooltipContent={
              <>
                <Typography sx={{ fontWeight: 700 }} variant="inherit">
                  {content.help_tooltip_header}
                </Typography>
                <RteContent data={content.help_tooltip_content} />
              </>
            }
          >
            <Button startIcon={<HelpIcon />} sx={{ color: 'text.primary' }}>
              <Typography component="span" mr={4} variant="subtitle2">
                {content.help_button_text}
              </Typography>
            </Button>
          </Tooltip>
        </Grid>
      )}
      {showLogout && <LogoutButton logoutText={content?.log_out_button_text ?? ''} />}
    </Grid>
  );
  const headerStyle = {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    pr: { md: 3 },
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  };
  return (
    <>
      <Grid container data-qa={dataQa} sx={{ ...headerStyle, height: '80px' }}>
        <Grid item pl={4} xs={!isMobile ? 3 : 5}>
          {content?.logoConnection?.edges?.[0]?.node?.url && (
            <Box component="img" src={content.logoConnection.edges[0].node.url} />
          )}
        </Grid>

        {showPerformanceTitle && (
          <Grid item textAlign="center" xs={6}>
            <Typography sx={{ color: 'text.primary', fontWeight: 700 }} variant="subtitle1">
              {content?.performance_title}
            </Typography>
          </Grid>
        )}

        {isMobile && (
          <Grid data-qa={`${dataQa}-actions`} item textAlign="right" xs>
            <Button
              aria-label="Menu actions"
              data-qa={`${dataQa}-menu-mobile`}
              onClick={() => setOpen(true)}
              sx={{ color: 'text.primary', minWidth: 46 }}
              variant="text"
            >
              <MoreVertIcon />
            </Button>
            <Drawer
              actions={<Box sx={{ display: 'flex', flexDirection: 'column' }}>{getMenuActions()}</Box>}
              onClose={() => setOpen(false)}
              open={open}
            />
          </Grid>
        )}

        {!isMobile && (
          <Grid data-qa={`${dataQa}-actions`} height="100%" item xs={showPerformanceTitle ? 3 : 12}>
            {getMenuActions()}
          </Grid>
        )}
      </Grid>
      {(showBackToAccountSummary || showBackToAccountDetails) && onBack && (
        <Grid
          data-qa={dataQa}
          item
          sx={{
            // TODO: Remove bottom border when the account details page has grey background in the top section
            borderBottom: showBackToAccountSummary ? '1px solid rgba(0, 0, 0, 0.12)' : '',
            px: 3,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            height: '45px',
          }}
        >
          <Box sx={{ display: 'flex', width: 1 }}>
            <Button
              onClick={onBack}
              startIcon={<ChevronLeftIcon fontSize="small" sx={{ color: 'primary.main' }} />}
              sx={{ pr: 2 }}
            >
              <Typography sx={{ color: 'text.primary', fontWeight: 400 }} variant="body2">
                {showBackToAccountSummary
                  ? content?.back_to_account_summary_button_text
                  : content?.back_to_account_details_button_text}
              </Typography>
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};
