import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

import { Button, Typography, useIsMediumScreen, usePostHog } from '@sigfig/digital-wealth-core';

import config from '../../config';

export const LogoutButton: React.FC<{ logoutText: string }> = ({ logoutText }): any => {
  const { isAuthenticated, logout } = useAuth0();
  const isMobile = useIsMediumScreen();
  const posthog = usePostHog();

  if (!isAuthenticated || !posthog) {
    return null;
  }

  return (
    <Button
      onClick={() => {
        posthog?.reset();
        logout({ returnTo: config.auth0.logoutUrl });
      }}
      sx={{ whiteSpace: 'nowrap' }}
      variant={isMobile ? 'text' : 'outlined'}
    >
      <Typography component="span" variant="body2">
        {logoutText || 'Log out'}
      </Typography>
    </Button>
  );
};
