/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { AuthedApp } from 'src/auth/AuthedApp';
import { DocusignCompleted } from 'src/components/DocusignCompleted';

import {
  AccountProfileUpdateState,
  CoreConfigProvider,
  createTheme,
  LoadingPage,
  ThemeProvider,
} from '@sigfig/digital-wealth-core';

import { defaultPartnerConfigs } from '../config';
import { PageRoute } from '../routes';
import { defaultTheme } from '../theme';

const AccountProfile = lazy(() => import('../components/AccountProfile'));
const AccountProfileDocusignCompleted = lazy(() => import('../components/AccountProfileDocusignCompleted'));
const DigitalWealth = lazy(() => import('../components/DigitalWealth'));
const DocusignWrapper = lazy(() => import('../components/DocusignWrapper'));
const EntryWrapper = lazy(() => import('../components/EntryWrapper'));
const PlaidLaunchWrapper = lazy(() => import('../components/PlaidLaunchWrapper'));

declare let window: Window;

function App(): unknown {
  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <CoreConfigProvider value={defaultPartnerConfigs}>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route element={<AuthedApp />}>
              <Route
                element={
                  <AccountProfile
                    accountProfileUpdateStatesOrder={[
                      AccountProfileUpdateState.EDIT_ACCOUNT_PROFILE,
                      AccountProfileUpdateState.CLIENT_DOCUSIGN,
                    ]}
                  />
                }
                path={PageRoute.AccountProfile}
              />
              <Route element={<DigitalWealth />} path={PageRoute.DigitalWealth} />
              <Route element={<DocusignWrapper />} path={PageRoute.Docusign} />
              <Route element={<EntryWrapper />} path="/" />
            </Route>
            <Route element={<AuthedApp authenticationOptional />}>
              <Route element={<DocusignCompleted />} path={PageRoute.DocusignComplete} />
              <Route element={<AccountProfileDocusignCompleted />} path={PageRoute.AccountProfileDocusignComplete} />
              {/* TODO: Add page not found component */}
            </Route>
            {/* Plaid route is not protected via Auth0 */}

            <Route element={<PlaidLaunchWrapper />} path={PageRoute.Plaid} />
          </Routes>
        </Suspense>
      </CoreConfigProvider>
    </ThemeProvider>
  );
}
export default App;
