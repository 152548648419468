import { AssociatedEntityType, DocusignTriggerSource } from '@sigfig/digital-wealth-core';

import { generateAbsolutePath, getSearchParams } from './utils/routes';

export enum PageRoute {
  AccountDetails = 'account-summary/:partyId/details/:managedProductId',
  AccountPerformance = 'account-summary/:partyId/performance/:managedProductId',
  AccountProfile = 'account-profile/:partyId/:managedProductId',
  AccountProfileDocusignComplete = 'docusignComplete/:partyId/account-profile/:managedProductId',
  AccountSummary = 'account-summary/:partyId',
  DigitalWealth = 'account-summary/:partyId/*',
  DigitalWealthAccountDetails = 'details/:managedProductId',
  DigitalWealthAccountPerformance = 'performance/:managedProductId',
  Docusign = 'docusign/:partyId/:managedProductId/:entityType?/:entityId?',
  DocusignComplete = 'docusignComplete/:partyId/:managedProductId?',
  Onboarding = 'onboarding/:partyId/:onboardingStage?',
  Plaid = 'plaid',
}

export const routes = {
  accountDetails: (partyId: string, managedProductId: string): string =>
    generateAbsolutePath(PageRoute.AccountDetails, { partyId, managedProductId }),
  accountPerformance: (partyId: string, managedProductId: string, source?: string): string =>
    generateAbsolutePath(PageRoute.AccountPerformance, { partyId, managedProductId }) + getSearchParams({ source }),
  accountProfile: (partyId: string, managedProductId: string, source: string) =>
    generateAbsolutePath(PageRoute.AccountProfile, { partyId, managedProductId }) + getSearchParams({ source }),
  accountProfileDocusignComplete: (partyId: string, managedProductId: string) =>
    generateAbsolutePath(PageRoute.AccountProfileDocusignComplete, { partyId, managedProductId }),
  accountSummary: (partyId: string): string => generateAbsolutePath(PageRoute.AccountSummary, { partyId }),
  docusign: (
    partyId: string,
    managedProductId: string | null,
    entityId: string | null,
    entityType: AssociatedEntityType | null,
  ) =>
    generateAbsolutePath(PageRoute.Docusign, { partyId, managedProductId, entityType, entityId }) +
    getSearchParams({ source: DocusignTriggerSource.ACCOUNT_SUMMARY }),
  docusignComplete: (partyId: string, managedProductId: string | null) =>
    generateAbsolutePath(PageRoute.DocusignComplete, { partyId, managedProductId }),
  onboarding: (partyId: string, onboardingStage: string | null) =>
    generateAbsolutePath(PageRoute.Onboarding, { partyId, onboardingStage }),
};
