import { findFieldValue, getImageAssetUrl } from '@sigfig/digital-wealth-core';

import { DocusignDaCompletedContent } from '.';
import { GetDocusignCompletedContent } from './contentstack/__generated__/GetDocusignCompletedContent';

export const getDocusignDACompletedContent = (data?: GetDocusignCompletedContent): DocusignDaCompletedContent => {
  const content = data?.all_docusign?.items?.[0];
  const textContent = content?.fields?.text ?? [];
  return {
    clientPage: {
      clientSignedMessage: content?.client_signed?.heading || '',
      clientSignedImage: getImageAssetUrl(content?.client_signed?.imageConnection),
      clientSignedSubHeading: content?.client_signed?.sub_heading || '',
      clientDeclinedMessage: content?.client_declined?.heading || '',
      clientDeclinedImage: getImageAssetUrl(content?.client_declined?.imageConnection),
      bankVericationFailedMessage: findFieldValue(textContent, 'BankVerificationFailedHeading'),
    },
    common: {
      backToAccountSummaryCta: findFieldValue(textContent, 'BackToAccountSummary'),
      docusignStatusOtherMessage: content?.docusign_status_other?.heading || '',
      docusignStatusOtherImage: getImageAssetUrl(content?.docusign_status_other?.imageConnection),
      docusignStatusOtherSubHeading: content?.docusign_status_other?.sub_heading || '',
      docusignStatusOtherClientSubHeading: content?.docusign_status_other?.client_sub_heading || '',
      docusignStatusOtherFASubHeading: content?.docusign_status_other?.fa_sub_heading || '',
    },
  };
};
