import {
  AssetAllocationTableColumns,
  ContentOptions,
  CoreConfig,
  defaultCoreConfig,
  FinancialAccountType,
  Locale,
  ManagedProductType,
  Partner,
  Product,
} from '@sigfig/digital-wealth-core';

import { getBaseUrl, getSymphonyPath } from '../utils';

export interface PartnerConfig extends CoreConfig {
  contentOptions: ContentOptions;
  defaultProductType: ManagedProductType;
}

export const defaultPartnerConfigs: PartnerConfig = {
  contentOptions: { locale: Locale.en_us, partner: Partner.NYL, product: Product.DigitalWealth },
  defaultProductType: ManagedProductType.DIGITAL_ADVICE,
  featureFlags: {
    ...defaultCoreConfig.featureFlags,
    allowEditManuallyLinkedBankAccounts: true,
    allowPlaidReAuthentication: true,
    isDocusignRequiredForFinancialAccountLinkageInRCE: true,
    isSuspendedAccountStateSupported: false,
    isManualLinkageForBankAccountSupported: true,
    isPlaidLinkageForBankAccountSupported: true,
    repCodeIdentifierName: 'NYL_REP_CODE',
    showDeleteButtonOnAccountDetailPage: false,
    showWarningForNonVerifiedFinancialAccountLinkages: true,
    msiEnabled: false,
  },
  components: {
    ...defaultCoreConfig.components,
    sfAccountDetails: {
      ...defaultCoreConfig.components.sfAccountDetails,
      enableViewPerformanceReport: false,
      showActualAllocationInDonut: false,
      showClosedAccountsInDropdown: true,
      showPortfolioNameInAssetAllocation: true,
    },
    sfLinkBankAccount: {
      ...defaultCoreConfig.components.sfLinkBankAccount,
      showNameOnAccountField: false,
      showBankNameField: false,
      showConfirmAccountNumberField: true,
      showConfirmRoutingNumberField: true,
    },
    sfAccountPerformance: {
      ...defaultCoreConfig.components.sfAccountPerformance,
      securityIds: ['BBG000BZZS63', 'BBG000TH7DF8'],
      showActualAllocationInDonut: false,
      showAdditionalClientsInfoForAccountType: [FinancialAccountType.JOINT],
      showFactSheetLinkInAssetAllocation: true,
      showFullAccountNumberInPdf: true,
      showPartnerMarketReviewUrl: false,
      showPortfolioNameInAssetAllocation: true,
    },
    sfAccountSummary: {
      ...defaultCoreConfig.components.sfAccountSummary,
      fallBackToStagedModelPortfolio: true,
      showContactAdvisorCta: false,
    },
    sfAccountProfile: {
      ...defaultCoreConfig.components.sfAccountProfile,
      showSecondaryCTA: true,
    },
    sfAddFunds: {
      disableDateAfterXDays: 365,
      disableDateCurrentYearForRetirementAccounts: true,
      disableDateMarketHolidays: true,
      disableDateWeekends: true,
    },
    sfClientDocuments: {
      ...defaultCoreConfig.components.sfClientDocuments,
      downloadDocumentBaseUrl: `${getBaseUrl()}${getSymphonyPath()}`,
    },
    sfFunding: {
      ...defaultCoreConfig.components.sfFunding,
      allowFundingToSetFundingValue: true,
      ageDataPoint: 'data-point:da-pro-age:integer',
      amountValidationBasedOnAccountTypes: [
        {
          accountType: FinancialAccountType.TRADITIONAL_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
        {
          accountType: FinancialAccountType.ROLLOVER_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
        {
          accountType: FinancialAccountType.ROTH_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
      ],
      brokerageAccountNumberValidationRegex: /[^a-z0-9-]/i,
      onlyEnableLikeToLikeJournaling: true,
      isFundLaterReasonEnabled: false,
    },
    sfPaperwork: {
      ageOfTerminationAttributeName: '',
      defaultCountryCode: 'AA',
      fetchRelatedContactsData: false,
      hiddenOptions: [
        {
          fieldName: 'data-point:identification_type:single_option',
          values: ['PASSPORT'],
          dependentOn: {
            comparisonType: 'NOT_EQUAL',
            contextVariable: 'isNonUsCitizen',
            fieldName: 'data_point:citizenship:single_option',
            value: '999',
          },
        },
      ],
      isCustomRegulatorySection: true,
      isPerStirpesEnabled: true,
      isReceivingEmailsControlledByEDeliveryEnrollment: false,
      isTrustTypeFieldsEnabled: false,
      onlyDriversIdentificationTypeForNonUsCitizen: true,
      prefillAddressCountryCode: false,
      prefillIdentifierCountryForDrivingLicense: false,
      statesWithUGMA: [],
      trustedContact: {
        allowSpecialCharacters: true,
        optionalCity: true,
        optionalEmailAddress: true,
        optionalState: true,
        optionalStreetAddress: true,
        optionalZipCode: true,
      },
    },
    sfDownloadQPR: {
      ...defaultCoreConfig.components.sfDownloadQPR,
      allocationDetailsColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.Asset,
        AssetAllocationTableColumns.TargetAllocation,
        AssetAllocationTableColumns.ActualAllocation,
        AssetAllocationTableColumns.Value,
      ],
      disclosureAtBottom: false,
      displayAllocationBar: false,
    },
    sfInvestmentRestrictions: {
      maximumRestrictions: null,
    },
    sfSupportContact: {
      showContactAdvisorCta: false,
    },
    sfWithdrawFunds: {
      ...defaultCoreConfig.components.sfWithdrawFunds,
      disableDateAfterMaxWithdrawalDate: true,
      disableDateAfterXDays: 365,
      disableDateCurrentYearForRetirementAccounts: true,
      disableDateMarketHolidays: true,
      disableDateTillAsSoonAsPossibleDate: true,
      disableDateWeekends: true,
      isDocusignRequiredForRetirementWithdrawals: false,
      showHardBlockForLowerLimit: true,
    },
  },
};

const iqa1Url = 'https://dw-iqa1-nyl.qa.sigfig.com';
const sqa1Url = 'https://dw-sqa1-nyl.qa.sigfig.com';
const sqa2Url = 'https://dw-sqa2-nyl.qa.sigfig.com';
const stgUrl = 'https://dw-stg-nyl.qa.sigfig.com';
const prodUrl = 'https://eagleprosperportfoliosclient.eaglestrategies.com';

const isIqa1 = window.location.origin.toLowerCase() === iqa1Url;
const isSqa1 = window.location.origin.toLowerCase() === sqa1Url;
const isSqa2 = window.location.origin.toLowerCase() === sqa2Url;
const isStg = window.location.origin.toLowerCase() === stgUrl;
export const isProd = window.location.origin.toLowerCase() === prodUrl;

const getConfigValue = ({
  iqa1,
  sqa1,
  sqa2,
  stg,
  prod,
  _,
}: {
  _: string;
  iqa1?: string;
  prod?: string;
  sqa1?: string;
  sqa2?: string;
  stg?: string;
}) => {
  if (isIqa1 && iqa1) {
    return iqa1;
  }
  if (isSqa1 && sqa1) {
    return sqa1;
  }
  if (isSqa2 && sqa2) {
    return sqa2;
  }
  if (isStg && stg) {
    return stg;
  }
  if (isProd && prod) {
    return prod;
  }
  return _;
};

export default {
  contentstack: {
    environment: getConfigValue({
      iqa1: 'dev',
      prod: 'nyl_prod',
      sqa1: 'nyl_sqa1',
      sqa2: 'nyl_sqa2',
      stg: 'nyl_prod',
      _: 'dev',
    }),
    deliveryToken: getConfigValue({
      iqa1: 'csaffbef3139b4b24aee2cc24c',
      prod: 'csca624325f9c1530b17380ef1',
      sqa1: 'cs77ab7edaf71c2bf8d09202c4',
      sqa2: 'cscb36302991d795f8b3efbde2',
      stg: 'csca624325f9c1530b17380ef1',
      _: 'csaffbef3139b4b24aee2cc24c',
    }),
  },
  auth0: {
    clientId: getConfigValue({
      iqa1: 'eXsx5HVeIohbx0bh3ZujF5KEfETiLxdx',
      sqa1: 'k4kbrnU1MMYU2L9HIDeJmDjkngX8ijAg',
      sqa2: 'fonAihNSNspRheAdQ6mQZudwWtWD7Skh',
      stg: 'hXwY3v5JjHfEIS18rpU3LTVuW8SoqlWN',
      prod: 'V99wRFhSiPJaquj7MzlMmc83ko7X6k5F',
      _: 'eXsx5HVeIohbx0bh3ZujF5KEfETiLxdx',
    }),
    domain: getConfigValue({
      iqa1: 'nyl-iqa.us.auth0.com',
      sqa1: 'nyl-sqa1.us.auth0.com',
      sqa2: 'nyl-sqa2.us.auth0.com',
      stg: 'nyl-stg.us.auth0.com',
      prod: 'nyl-prod.us.auth0.com',
      _: 'nyl-iqa.us.auth0.com',
    }),
    logoutUrl: getConfigValue({
      iqa1: iqa1Url,
      sqa1: sqa1Url,
      sqa2: sqa2Url,
      stg: stgUrl,
      prod: prodUrl,
      _: iqa1Url,
    }),
  },
  newRelic: {
    licenseKey: '3288c5f750',
    applicationId: getConfigValue({
      stg: '718348392',
      prod: '718348392',
      _: '718348394',
    }),
  },
  sentry: {
    dsn: 'https://287efe51989149c5a00884d5fd3ff59e@o14749.ingest.sentry.io/4505150216798208',
    environment: getConfigValue({
      iqa1: 'iqa1',
      sqa1: 'sqa1',
      stg: 'staging',
      prod: 'production',
      _: 'dev',
    }),
  },
  posthog: {
    token: getConfigValue({
      prod: 'phc_YwMtXmyJjHJ9AiU0tP7Hs1r9UQwYWKp2rcD5c6W94XF',
      _: 'phc_DfeG81drxq7Akc7hhpneG2qiEebBVhyz2G2ohatVv1u',
    }),
  },
};
