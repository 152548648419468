export { default as BoldFont } from './fonts/tahoma-bold.ttf';
export { default as NormalFont } from './fonts/tahoma.ttf';
import { alpha, experimental_sx as sx } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

import { createDefaultTheme, ThemeOptions } from '@sigfig/digital-wealth-core';

export enum FontFamilies {
  Georgia = 'Georgia',
  Tahoma = 'Tahoma',
  TahomaBold = 'Tahoma Bold',
}

const palette = {
  action: {
    background: '#EBF7F5',
    disabled: '#868B8D',
    disabledBackground: '#E9E8E8',
  },
  error: { main: '#CC0000' },
  info: { main: '#1BB3BC' },
  primary: {
    main: '#0C3C52',
    white: '#FFFFFF',
  },
  secondary: { main: '#0C3C52' },
  success: { main: '#63BA68' },
  text: {
    primary: '#434343',
    secondary: '#8C8C8C',
    disabled: '#BFBFBF',
  },
  warning: { main: '#E97300' },
  background: {
    default: '#FAFAFA',
    light: '#FEECED',
  },
};

const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      disableElevation: false,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      sizeLarge: {
        fontSize: '16px',
      },
      sizeMedium: {
        fontSize: '14px',
      },
      sizeSmall: {
        fontSize: '14px',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        color: palette.primary.main,
        '&.MuiToggleButton-root': {
          borderColor: alpha(palette.primary.main, 0.5),
        },
        '&.Mui-selected': {
          backgroundColor: palette.action.background,
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#BFBFBF',
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#666666',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#BFBFBF',
        },
      },
    },
  },
};

const breakpoints = createBreakpoints({});
const typography: ThemeOptions['typography'] = {
  h1: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 36,
    fontWeight: 400,
    letterSpacing: '-0.25px',
    lineHeight: '60px',
  },
  h2: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 26,
    fontWeight: 400,
    lineHeight: '60px',
    [breakpoints.down('md')]: {
      fontSize: 36,
      lineHeight: '42px',
    },
  },
  h3: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 22,
    fontWeight: 400,
    lineHeight: '44px',
  },
  h4: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 22,
    fontWeight: 400,
    lineHeight: '40px',
  },
  h5: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '34px',
  },
  h6: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '32px',
  },
  subtitle1: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
  },
  subtitle2: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
  body1: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 16,
    fontWeight: 400,
    fontWeightBold: 700,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
  },
  button: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0,
  },
  caption: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
  overline: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '28px',
  },
};

const sfComponents: ThemeOptions = {
  sfAccountDetails: {
    styles: {
      dropdownLabel: {
        '.MenuItem-label': {
          overflow: 'visible !important',
        },
      },
    },
  },
  sfDownloadProposal: {
    styles: {
      downloadButton: {
        fontSize: 16,
        fontWeight: 16,
        lineHeight: '24px',
        textDecoration: 'underline',
      },
    },
  },
  sfDropdown: {
    styles: {
      outlinedClassic: {
        inputLabel: {
          ml: 0,
          mb: 0,
        },
      },
    },
  },
  sfMadLibs: {
    root: {
      '.MuiInput-input': {
        color: 'primary.main',
      },
    },
    styles: {
      disclosureColor: 'text.primary',
      headingColor: 'primary.main',
      secondaryInputColor: 'text.secondary',
    },
    typographyVariants: {
      heading: 'h3',
      input: 'h3',
      dropdown: 'h3',
    },
  },
  sfPaperwork: {
    styles: {
      sectionCTA: typography.body1,
    },
  },
  sfPerformanceChart: {
    styles: {
      linkDecoration: 'none',
      lineColor: palette.primary.main,
    },
  },
  sfReturnsChart: {
    styles: {
      colors: {
        default: palette.primary.main,
        BBG000BZZS63: '#4CAF50',
        BBG000TH7DF8: '#B71C1C',
      },
    },
  },
};

export const defaultTheme = createDefaultTheme({
  components,
  palette,
  typography,
  ...sfComponents,
});
