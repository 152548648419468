import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom-v5-compat';

import {
  ApolloProvider,
  cacheTypePolicies,
  ErrorComponent,
  from,
  getApolloClient,
  getContentstackHttpLinkConfig,
  HttpLink,
  InMemoryCache,
  LoadingPage,
  setContext,
  sfPosthog,
  useCoreConfig,
  usePostHog,
} from '@sigfig/digital-wealth-core';

import config, { PartnerConfig } from '../../config';
import { routes } from '../../routes';
import { getSymphonyPath } from '../../utils';
import { ProtectedRoute } from '..';
import { KeepAlive } from '../KeepAlive';

export const AuthedApp = ({ authenticationOptional }: { authenticationOptional?: boolean }) => {
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const posthog = usePostHog();
  const { isAuthenticated: isAuthenticatedViaAuth0, isLoading, error, user, logout } = useAuth0();
  const [jwt, setJwt] = useState('');
  const [loggedInPartyId, setLoggedInPartyId] = useState('');
  const namespace = 'https://fc.sigfig.com';

  sfPosthog.useCaptureSpaPageViews(useLocation());

  useEffect(() => {
    if (user) {
      setJwt(user[`${namespace}:frontEndJwt`]);
    }
  }, [user]);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = jwt ? `Bearer ${jwt}` : '';
  }, [jwt]);

  useEffect(() => {
    const contextPartyId = user?.[`${namespace}:inContextPartyId`];
    if (contextPartyId && loggedInPartyId !== contextPartyId) {
      setLoggedInPartyId(contextPartyId);
      posthog?.identify(contextPartyId);
    }
  }, [user, loggedInPartyId, posthog]);

  const symphonyAuthLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (
      (networkError as any)?.result?.status === 403 &&
      (networkError as any)?.result?.code === 'AUTHENTICATION_INVALID_UNAUTHORIZED'
    ) {
      posthog?.reset();
      logout({ returnTo: config.auth0.logoutUrl });
    }
  });

  // TODO: Fix apollo client parameters
  // https://jira.sigfig.com/browse/DA2-696
  const getSymphonyHttpLinkConfig = (uri: string) => ({
    name: 'symphony',
    link: from([
      errorLink,
      symphonyAuthLink.concat(
        new HttpLink({
          uri,
          fetch,
        }),
      ),
    ]),
  });
  const symphonyUrl = `${getSymphonyPath()}/ui/graphql`;

  const apolloClient = getApolloClient({
    links: [
      getContentstackHttpLinkConfig(config.contentstack.environment, config.contentstack.deliveryToken),
      getSymphonyHttpLinkConfig(symphonyUrl),
    ],
    cache: new InMemoryCache({
      typePolicies: {
        ...cacheTypePolicies,
      },
    }),
  });

  return (
    <ApolloProvider client={apolloClient}>
      {isLoading || (isAuthenticatedViaAuth0 && jwt === '') ? (
        <LoadingPage />
      ) : error ? (
        <ErrorComponent contentOptions={contentOptions} isAuthorizationError />
      ) : authenticationOptional ? (
        <Outlet />
      ) : (
        <ProtectedRoute
          component={() => (
            <KeepAlive redirectUrl={routes.accountSummary(loggedInPartyId)}>
              <Outlet context={loggedInPartyId} />
            </KeepAlive>
          )}
        />
      )}
    </ApolloProvider>
  );
};
