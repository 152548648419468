/* eslint-disable @typescript-eslint/no-empty-function */
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import debounce from 'lodash.debounce';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { SessionTimeoutMessage, useCoreConfig, usePostHog } from '@sigfig/digital-wealth-core';

import config, { PartnerConfig } from '../../config';
import { getSymphonyPath } from '../../utils';

interface Props {
  children: ReactNode;
  redirectUrl: string;
}

export const KeepAlive: React.FC<Props> = ({ children, redirectUrl }) => {
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const { isAuthenticated, user, logout } = useAuth0();
  const timeoutMessageRef = useRef({ resetSession: () => {} });
  const history = createBrowserHistory();
  const posthog = usePostHog();
  const [sessionTimeout, setSessionTimeout] = useState(user?.['https://fc.sigfig.com:sessionTimeout']);
  const callKeepAlive = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const userJwt = user?.['https://fc.sigfig.com:frontEndJwt'];
        const result = await axios({
          method: 'post',
          url: `${getSymphonyPath()}/auth/v1/keep-alive`,
          headers: {
            Authorization: `Bearer ${userJwt}`,
          },
        });
        if (result.data && result.data.timeOutAt) {
          const newTimeoutSeconds = (new Date(result.data.timeOutAt as string).getTime() - new Date().getTime()) / 1000;
          setSessionTimeout(newTimeoutSeconds);
        }
        // on any session refresh, ensure to reset the timeout message to start over again.
        if (timeoutMessageRef.current) {
          timeoutMessageRef.current.resetSession();
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    ['click', 'wheel', 'message'].forEach(event => window.addEventListener(event, debounce(callKeepAlive, 1000)));
    return () => {
      ['click', 'wheel', 'message'].forEach(event => window.removeEventListener(event, debounce(callKeepAlive, 1000)));
    };
  }, [callKeepAlive]);

  return (
    <>
      {children}
      {isAuthenticated && (
        <SessionTimeoutMessage
          contentOptions={contentOptions}
          logoutCallback={() => {
            setTimeout(() => {
              posthog?.reset();
              logout({ returnTo: config.auth0.logoutUrl });
            }, 5000);
          }}
          navigateCallback={() => {
            history.push(redirectUrl);
            window.location.reload();
          }}
          ref={timeoutMessageRef}
          refreshCallback={callKeepAlive}
          timeOutInSeconds={sessionTimeout}
          timeoutThreshold={100}
        />
      )}
    </>
  );
};
