import { QueryHookOptions, QueryResult } from '@apollo/client';
import { loader } from 'graphql.macro';

import { useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetDocusignCompletedContent,
  GetDocusignCompletedContentVariables,
} from './__generated__/GetDocusignCompletedContent';

const queries = loader('./query.gql');

export const useGetDocusignCompletedContent = (
  options?: QueryHookOptions<GetDocusignCompletedContent, GetDocusignCompletedContentVariables>,
): QueryResult<GetDocusignCompletedContent, GetDocusignCompletedContentVariables> => {
  return useContentstackQuery(queries, options);
};
