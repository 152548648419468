import { useAuth0 } from '@auth0/auth0-react';
import { FC, useMemo } from 'react';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom-v5-compat';
import { PartnerConfig } from 'src/config';
import { routes } from 'src/routes';

import {
  AlertAndLoading,
  Container,
  Grid,
  isInIframe,
  LegalDocumentStatus,
  OnboardingPageCtas,
  OnboardingPageHeader,
  Typography,
  useCoreConfig,
} from '@sigfig/digital-wealth-core';

import { Header } from '../Header';

import { useGetDocusignCompletedContent } from './contentstack';
import { getDocusignDACompletedContent } from './utils';

export enum DocusignUserType {
  Client = 'Client',
  FA = 'FA',
}
export interface DocusignDaCompletedContent {
  clientPage: {
    bankVericationFailedMessage: string;
    clientDeclinedImage: string;
    clientDeclinedMessage: string;
    clientSignedImage: string;
    clientSignedMessage: string;
    clientSignedSubHeading: string;
  };
  common: {
    backToAccountSummaryCta: string;
    docusignStatusOtherClientSubHeading: string;
    docusignStatusOtherFASubHeading: string;
    docusignStatusOtherImage: string;
    docusignStatusOtherMessage: string;
    docusignStatusOtherSubHeading: string;
  };
}
interface Props {
  dataQa?: string;
}
export const DocusignCompleted: FC<Props> = ({ dataQa = 'docusign-da-pro-completed' }) => {
  const { isAuthenticated } = useAuth0();
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const { partyId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const signingStatus = searchParams.get('status');
  const userType = searchParams.get('userType');
  const financialAccountVerificationStatus = searchParams.get('financialAccountVerificationStatus');
  const isBankVerificationFailed = !!(
    financialAccountVerificationStatus && financialAccountVerificationStatus !== 'VERIFIED'
  );
  const {
    data: docusignCompletedContent,
    loading: docusignCompletedLoading,
    error: docusignCompletedError,
  } = useGetDocusignCompletedContent({
    variables: contentOptions,
  });
  const redirectToAccountSummary = () => navigate(routes.accountSummary(partyId ?? ''));
  const { clientPage, common } = getDocusignDACompletedContent(docusignCompletedContent);

  const content = useMemo(() => {
    if (signingStatus !== LegalDocumentStatus.SUCCEEDED && signingStatus !== LegalDocumentStatus.DECLINED) {
      return {
        message: common.docusignStatusOtherMessage,
        image: common.docusignStatusOtherImage,
        subHeading:
          userType?.toString().toUpperCase() === DocusignUserType.Client.toString().toUpperCase()
            ? common.docusignStatusOtherClientSubHeading
            : common.docusignStatusOtherSubHeading,
      };
    }

    if (signingStatus === LegalDocumentStatus.SUCCEEDED) {
      if (isBankVerificationFailed) {
        return {
          subHeading: clientPage.bankVericationFailedMessage,
          image: clientPage.clientSignedImage,
        };
      }
      return {
        message: clientPage.clientSignedMessage,
        image: clientPage.clientSignedImage,
        subHeading: clientPage.clientSignedSubHeading,
      };
    }
    if (signingStatus === LegalDocumentStatus.DECLINED) {
      return {
        message: clientPage.clientDeclinedMessage,
        image: clientPage.clientDeclinedImage,
        subHeading: clientPage.clientDeclinedMessage,
      };
    }
  }, [docusignCompletedContent?.all_docusign?.items, signingStatus, userType]);

  if (isInIframe() && window.top) {
    window.top.location.href = window.location.href;
    return null;
  }

  if (docusignCompletedLoading || docusignCompletedError) {
    return (
      <AlertAndLoading
        ariaLabel="Loading docusign data"
        contentOptions={contentOptions}
        error={docusignCompletedError}
        loading={docusignCompletedLoading}
      />
    );
  }

  if (!content) {
    return <></>;
  }

  return (
    <>
      <Header onBack={() => navigate(routes.accountSummary(partyId ?? ''))} showBackToAccountSummary />
      <Container maxWidth="md">
        <Grid container direction="column">
          <OnboardingPageHeader headerIcon={content.image} />
          {content.subHeading && (
            <Typography sx={{ pb: 5, textAlign: 'center' }} variant="h3">
              {content.subHeading}
            </Typography>
          )}
        </Grid>
        {isAuthenticated && (
          <OnboardingPageCtas
            backButtonProps={{ onClick: redirectToAccountSummary }}
            content={{
              backCta: common.backToAccountSummaryCta,
              nextCta: '',
            }}
            dataQa={`${dataQa}-faCtas`}
            sx={{ m: 0 }}
          />
        )}
      </Container>
    </>
  );
};
